@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap");

* {
  font-family: "Raleway", sans-serif;
}

.mono-font {
  font-family: "DM Mono", monospace;
}

.equal-rows {
  grid-auto-rows: 1fr;
}
